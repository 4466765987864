import React from 'react';

import { CdnFeatureTab } from './CdnFeatureTab/CdnFeatureTab';
import { Tabs } from '@components/Tabs/Tabs';
import { routes } from '@routes';

import flexiblePricingImage from './flexible-pricing.jpg';
import intuitiveUiImage from './intuitive-ui.png';
import techSupportImage from './tech-support.jpg';
import useCasesImage from './use-cases.jpg';

export const CdnFeaturesTabs: React.FC = () => (
  <Tabs
    items={[
      {
        name: 'Individual approach',
        content: (
          <CdnFeatureTab
            button={{ href: routes.requestdOffer, text: 'Request tailored offer' }}
            description="Customization matters and even a little twist can bring performance improvements. Our Client Solutions & Engineering teams are ready to find the best technical and business solution for you."
            highlights={[
              {
                id: 'rules',
                text: (
                  <>
                    <strong>Custom rules & configs</strong>, <br />
                    multilayer caching setup
                  </>
                ),
              },
              {
                id: 'trial',
                text: (
                  <>
                    <strong>Free trial</strong> with customizable <br />
                    traffic volume and duration
                  </>
                ),
              },
              {
                id: 'infrastructure',
                text: (
                  <>
                    <strong>Dedicated CDN infrastructure</strong> <br />
                    for daily traffic at petabyte levels
                  </>
                ),
              },
              {
                id: 'flexible',
                text: (
                  <>
                    <strong>Flexible development</strong> <br />
                    for custom scenarios
                  </>
                ),
              },
            ]}
            image={useCasesImage}
            title="Individual approach"
            tooltip={{
              placement: 'top',
              position: { left: 40, top: 20 },
              text: (
                <>
                  Martina. Sales. Always happy to help, <br /> as you can see.
                </>
              ),
            }}
          />
        ),
      },
      {
        name: 'Flexible pricing',
        content: (
          <CdnFeatureTab
            button={{ href: routes.requestdOffer, text: 'Get quote' }}
            description="Our clients stay with us because of satisfaction with the service, not because of contractual obligations."
            highlights={[
              {
                id: 'commitment',
                text: (
                  <>
                    <strong>No-commitment</strong> - adjust your <br />
                    plan based on your traffic needs
                  </>
                ),
              },
              {
                id: 'rate',
                text: (
                  <>
                    <strong>Single global rate</strong> regardless of <br />
                    traffic geo-distribution
                  </>
                ),
              },
              {
                id: 'transparent',
                text: (
                  <>
                    <strong>Transparent pricing</strong> based on <br />
                    data transferred
                  </>
                ),
              },
              {
                id: 'competitive',
                text: (
                  <>
                    <strong>Competitive pricing</strong> compared <br />
                    to other providers
                  </>
                ),
              },
            ]}
            image={flexiblePricingImage}
            title="Flexible pricing"
            tooltip={{
              placement: 'bottom',
              position: { left: 50, top: 50 },
              text: 'If you spot Tomas and Jana plotting together, consider yourself lucky. Great things are about to happen.',
            }}
          />
        ),
      },
      {
        name: '24/7 tech support',
        content: (
          <CdnFeatureTab
            button={{ href: routes.contact, text: 'Contact us' }}
            description="Our engineers know the service inside out and they are ready to assist you at any time - from describing various CDN77 features, to debugging and system configuration."
            highlights={[
              {
                id: 'chat',
                text: (
                  <>
                    <strong>Live chat</strong> with response time <br />
                    under 60 seconds
                  </>
                ),
              },
              {
                id: 'dev',
                text: (
                  <>
                    <strong>Dedicated account manager</strong> - <br className="d-hide-lg-only" />
                    we know your use-case
                  </>
                ),
              },
              {
                id: 'issues',
                text: (
                  <>
                    <strong>94% of issues solved</strong> directly via <br />
                    live chat
                  </>
                ),
              },
              {
                id: 'slack',
                text: (
                  <>
                    <strong>Slack Connect channel&trade; </strong>with <br />
                    L2 teams available
                  </>
                ),
              },
            ]}
            image={techSupportImage}
            title="24/7 in-house tech support"
            tooltip={{
              placement: 'bottom-start',
              position: { left: 30, top: 64 },
              text: 'Med is the kind of guy that everyone likes to be around: the one that answers your questions and figures out how to solve any tech issue.',
            }}
          />
        ),
      },
      {
        name: 'Intuitive UI',
        content: (
          <CdnFeatureTab
            button={{ href: routes.requestdOffer, text: 'Get quote' }}
            description="CDN77's Client Panel is designed to be an intuitive environment for straightforward CDN management with detailed analytics included."
            highlights={[
              {
                id: 'api',
                text: (
                  <>
                    <strong>REST API</strong> for CDN <br /> management
                  </>
                ),
              },
              {
                id: 'custom',
                text: (
                  <>
                    <strong>Custom coded</strong> <br />
                    interface & analytics available
                  </>
                ),
              },
              {
                id: 'compactible',
                text: (
                  <>
                    <strong>S3-compatible </strong>object storage
                  </>
                ),
              },
              {
                id: 'role',
                text: (
                  <>
                    <strong>Role-based </strong>access
                  </>
                ),
              },
            ]}
            image={intuitiveUiImage}
            title="Intuitive UI"
            tooltip={{
              placement: 'top',
              position: { left: 55, top: 30 },
              text: 'CDN77 client Panel. Pampered by in-house designers and developers alike.',
            }}
          />
        ),
      },
    ]}
    spacingBottom="lg"
  />
);
