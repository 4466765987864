import React from 'react';
import type { PopperOptions } from 'react-popper-tooltip';

import { TooltipBubble } from '@components/Tooltip/TooltipBubble/TooltipBubble';

import styles from './ImageTooltip.module.scss';

export interface IProps {
  children: JSX.Element;
  text: string | JSX.Element;
  position: { top: number; left: number };
  placement?: PopperOptions['placement'];
}

export const ImageTooltip: React.FC<IProps> = ({ text, position, placement, children }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <TooltipBubble
        isInteractive={false}
        isVisible={isVisible}
        options={{ placement }}
        text={text}
      >
        {({ setTriggerRef, tooltip }) => (
          <>
            {tooltip}
            <div
              ref={setTriggerRef}
              className={styles.tooltipTarget}
              style={{ left: `${position.left}%`, top: `${position.top}%` }}
            />
          </>
        )}
      </TooltipBubble>
      {children}
    </div>
  );
};
