import React from 'react';

import { Button } from '@components/Button/Button';
import type { IImage } from '@components/Image/Image';
import { Image } from '@components/Image/Image';
import type { IProps as IImageTooltipProps } from '@components/ImageTooltip/ImageTooltip';
import { ImageTooltip } from '@components/ImageTooltip/ImageTooltip';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { Text } from '@components/Text/Text';

import styles from './CdnFeatureTab.module.scss';

interface IProps {
  image: IImage;
  title: string;
  description: string;
  highlights: { id: string; text: JSX.Element }[];
  button: {
    href: string;
    text: string;
  };
  tooltip: Omit<IImageTooltipProps, 'children'>;
}

export const CdnFeatureTab: React.FC<IProps> = ({
  image,
  title,
  description,
  highlights,
  button,
  tooltip,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.decorationWrapper}>
      <ImageTooltip {...tooltip}>
        <div className={styles.imageWrapper}>
          <Image alt="" height={480} src={image} theme="rounded" width={480} />
        </div>
      </ImageTooltip>
    </div>
    <div className={styles.content}>
      <Text align="left" className="mb-4" color="gray900" size={30} tag="h3" weight="bold">
        {title}
      </Text>
      <Paragraph className="mb-6" size="lg">
        {description}
      </Paragraph>
      <ul className={styles.list}>
        {highlights.map(({ text, id }) => (
          <li key={id} className={styles.listItem}>
            <Paragraph>{text}</Paragraph>
          </li>
        ))}
      </ul>
      <Button href={button.href} theme="button" variant="secondary">
        {button.text}
      </Button>
    </div>
  </div>
);
