import classNames from 'classnames/bind';
import React from 'react';

import styles from './ColumnsLayout.module.scss';

interface IProps {
  className?: string;
  itemClassName?: string;
  theme?: 'light' | 'dark';
  lines?: 'pulled' | 'aligned';
  children: React.ReactNode[];
}

const cx = classNames.bind(styles);

export const ColumnsLayout: React.FC<IProps> = ({
  theme = 'light',
  lines = 'pulled',
  className,
  itemClassName,
  children,
}) => (
  <div className={cx('wrapper', `max-${children.length}`, `lines-${lines}`, className)}>
    {React.Children.map(children, (item, index) => (
      <div key={index} className={cx('item', theme, itemClassName)}>
        {item}
      </div>
    ))}
  </div>
);
