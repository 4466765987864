import classNames from 'classnames/bind';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Image } from '@components/Image/Image';
import { Text } from '@components/Text/Text';

import styles from './UseCases.module.scss';
import assetsImage from './assets.jpg';
import imagesImage from './images.jpg';
import softwareImage from './software.jpg';
import videoImage from './video.jpg';

interface IProps {
  className?: string;
}

const data = [
  {
    photo: videoImage,
    title: 'Video delivery',
    description: 'CDN optimized for VOD and live video platforms.',
  },
  {
    photo: softwareImage,
    title: 'Software & gaming',
    description: 'Downloads & updates, in-game assets, VR apps.',
  },
  {
    photo: imagesImage,
    title: 'Images',
    description: 'Deliver images of any size & format on all platforms.',
  },
  {
    photo: assetsImage,
    title: 'Other assets',
    description: 'HTML, CSS, JS files or any static file type or request.',
  },
];

const cx = classNames.bind(styles);

export const UseCases: React.FC<IProps> = () => {
  const [inViewRef, isVisible] = useInView({
    threshold: 1,
    rootMargin: '0px 0px -300px 0px',
    triggerOnce: true,
  });

  return (
    <>
      <div ref={inViewRef} />
      <div className={styles.wrapper}>
        {data.map(({ photo, title, description }, index) => (
          <div
            key={title}
            className={cx('item', { isVisible })}
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            <div className={styles.imageWrapper}>
              <Image alt="" height={180} src={photo} theme="rounded" width={240} />
            </div>
            <div className={styles.content}>
              <Text className="mb-2" color="white" size={20} tag="strong">
                {title}
              </Text>
              <Text color="white" opacity={70} size={16} tag="p">
                {description}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
